import React, { useState } from 'react'
import { Alert } from 'shards-react'
import fire from 'firebase'
import firebase from './firebase'
import { FormInput, Form, Button } from 'shards-react'
import GoogleSignInButton from './GoogleSignInButton'
import "./Login.css"

const provider = new fire.auth.GoogleAuthProvider();

const Login = ({ setPage }) => {

  const [ username, setUsername ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ validationError, setValidationError ] = useState(null)

  const login = () => {
    firebase.auth().signInWithEmailAndPassword(username, password)
      .catch((e) => {
        if (e && e.message) {
          setValidationError(e.message)
        } else {
          setValidationError("Something went wrong :s")
        }
      });
  };

  return (
    <div className="form-middle" style={{ textAlign: 'center' }}>
    <div className="form-middle-inner">
      <Form>
        <h1>GreatList</h1>
        <FormInput
          placeholder="email"
          value={username}
          type="email"
          onChange={e => setUsername(e.target.value)}
        />
        <FormInput
          style={{ marginTop: 10 }}
          placeholder="password"
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <Button
          theme="success"
          style={{ marginTop: 20, marginBottom: 20, width: '100%', fontWeight: 'bold', fontSize: 16 }}
          onClick={(e) => {
            e.preventDefault()
            login()
          }}
        >Sign in</Button>
        { validationError && <Alert theme="danger">{validationError}</Alert> }
        <a
          style={{ marginTop: 20, marginBottom: 20, width: '100%', fontSize: 13 }}
          onClick={(e) => {
            e.preventDefault()
            setPage('Forget')
          }}
        >Forgot Password?</a>
      </Form>
    </div>
    <div style={{ margin: 10, textAlign: 'center', fontSize: 14 }}>
    Dont have an account? &nbsp;
      <a
        onClick={(e) => {
          e.preventDefault()
          setPage('Signup')
        }}
      >Create Account</a>
       </div>
    <GoogleSignInButton
      onClick={() => firebase.auth().signInWithPopup(provider)}
    />
    </div>
  )
}

export default Login