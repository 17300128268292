import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import './index.css';
import AuthApp from './AuthApp'
import * as serviceWorker from './serviceWorker';

Sentry.init({dsn: "https://c132bf5a71a64b059baa89b1b5151a67@sentry.io/1757734"});

ReactDOM.render(<AuthApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
