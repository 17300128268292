import React, { useState, useEffect } from 'react'
import { Card, FormCheckbox, CardBody, Fade, Button } from 'shards-react'
import './about.css'
import robot from './robot.png'

const About = ({ setPage }) => {

  const [ visible, setVisible ] = useState(true)
  const [ speech, setSpeech ] = useState(0)
  const sayings = [
    "Hello and welcome!",
    "I am here to support you",
    "Todo lists are great fun",
    "Some say this is the GOAT todo list",
    "Baaa",
    "Lets get started and make great things happen!"
  ]

  useEffect(() => {
    let count = 0
    const interval = setInterval(() => {
      setVisible(false)
      setTimeout(() => {
        if (count < sayings.length - 1) {
          count = count + 1
        } else {
          count = 0
        }
        setSpeech(count)
        setVisible(true)
      }, 500)
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
<div className="about-middle">
  <div className="flex-middle">
  <div style={{ display: 'flex' }}>
  <Card>
    <CardBody>
    <h3>GreatList</h3>
    <p style={{ fontSize: 15 }}>
      GreatList is a <span style={{ fontWeight: 'bold' }}>simple</span>, <span style={{ fontWeight: 'bold' }}>fun</span> and intuitive <span style={{ fontWeight: 'bold' }}>productivity</span> tool to keep you on track and feeling great as you achieve the goals you want to.
    </p>
    <FormCheckbox checked={true}>Simple and fun task lists</FormCheckbox>
    <FormCheckbox checked={true}>Sub-tasks, notes, due dates...</FormCheckbox>
    <FormCheckbox checked={true}>Real time sync</FormCheckbox>
    <FormCheckbox checked={true}>Forever independent</FormCheckbox>
    <div style={{ textAlign: 'center' }}>
      <Button theme="success" style={{ marginTop: 30, marginLeft: 20, marginRight: 20, fontWeight: 'bold' }} onClick={() => setPage('Signup')} >Get started now</Button>
    </div>
    </CardBody>
  </Card>
  </div>
  <div className="robot-section" >
  <img src={robot} id="robot" height="220px" width="240px"></img>
  <Fade in={visible} timeout={1000}>
    <div className="speech-bubble" >{sayings[speech]}</div>
  </Fade>
  </div>
  </div>
</div>)
}

export default About