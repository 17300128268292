import firebase from 'firebase'
import moment from 'moment'
import fb, { db } from '../firebase'

const storageRef = firebase.storage().ref();

const toFsTypeActivity = activity => {
  let fsActivity = activity
  if (typeof activity.timestamp === 'string') {
    try {
      fsActivity.timestamp = moment(fsActivity.timestamp, 'DD.MM.YYYY HH:mm').toDate()
    } catch (e) {
      console.log('dob', e)
    }
  }
  return fsActivity
}

export const fromFsTypeActivity = activity => {
  let fsActivity = activity
  if (activity.timestamp && typeof activity.timestamp === 'object') {
    fsActivity.timestamp = moment(fsActivity.timestamp.toDate()).format('DD.MM.YYYY HH:mm')
  }
  return activity
}

export const updateUserEmail = ({ uid, email }) => db.collection('users').doc(uid).set(
  {
    email: email,
  },
  { merge: true }
)
.catch(e => {
})

export const updateUser = ({ uid, ...rest }) => db.collection('users').doc(uid).set(
  rest,
  { merge: true }
)

export const setUserPushToken = (uid, token) => db.collection('users').doc(uid).set(
  {
    pushTokens: firebase.firestore.FieldValue.arrayUnion(token),
  },
  { merge: true }
)
.catch(e => {
})

export const getUserFeed = uid => db.collection('users').doc(uid).collection('activityfeed').orderBy('timestamp', 'desc').limit(10)

export const updateUserFeedSeen = (uid, feedId) => db.collection('users')
  .doc(uid).collection('activityfeed').doc(feedId).update({'seen': true})

export const addActivityFeedItem = (uid, item) => db.collection('users').doc(uid).collection('activityfeed').doc(item.id).set(toFsTypeActivity(item), {
  merge: true
})

export const getUserByEmail = email => db.collection('users').where('email', '==', email).get()

export const deleteUser = uid => {
  db.collection('users').doc(uid).delete()
  firebase.auth().currentUser.delete()
}
// TODO delete other items connected to user
export const getUser = uid => db.collection('users').doc(uid).get()

export const updateUserImage = (uid, imageFile) => storageRef.child(`userprofile/${uid}`).put(imageFile)
  .then((snapshot) => snapshot.ref.getDownloadURL())
  .then((downloadURL) => updateUser({ uid, photoURL: downloadURL }))

export const subscribeUser = uid => db.collection('users').doc(uid)

export const inviteUser = (email, usersEmail, listName) => {
  const a = fb.functions('europe-west2').httpsCallable('inviteUser')
  return a({
    email,
    usersEmail,
    listName
  })
  .then(() => console.log('success'))
  .catch((e) => console.log('err', e));
} 