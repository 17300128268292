import firebase from 'firebase'
import firebaseConfig from './firebaseConfig'

const fb = firebase.initializeApp(firebaseConfig)

try {
firebase.messaging().usePublicVapidKey('BIbvh5ZUZ2dfpVXaqP1PNIRvGidMdrqueysr7WvehPshP3NNxOesCO2EKwz0Ow-MewrIRzPoEZVwM9tAYt4PbM4')
} catch (e) {
  console.log('this browser dos not support push notifications, please use chrome or firefox.')
}
export default fb
firebase.firestore().enablePersistence()
export const db = fb.firestore()