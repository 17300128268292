import React, { useState } from 'react'
import fire from 'firebase'
import firebase, { db } from './firebase'
import { FormInput, Form, Button, Alert } from 'shards-react'
import "./Login.css"
import GoogleSignInButton from './GoogleSignInButton'

const provider = new fire.auth.GoogleAuthProvider();

const Signup = ({ setPage }) => {

  const [ username, setUsername ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ validationError, setValidationError ] = useState(null)

  const signup = () => {
    firebase.auth().createUserWithEmailAndPassword(username, password)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return db.collection('users').doc(authUser.user.uid).set(
          {
            email: username,
          },
          { merge: true },
        )
      })
      .catch((e) => {
        if (e && e.message) {
          setValidationError(e.message)
        } else {
          setValidationError("Something went wrong :s")
        }
      });
  };

  return (
    <div className="form-middle" style={{ textAlign: 'center' }}>
    <div className="form-middle-inner">
      <Form>
        <h1>GreatList</h1>
        <FormInput
          placeholder="email"
          value={username}
          type="email"
          onChange={e => setUsername(e.target.value)}
        />
        <FormInput
          style={{ marginTop: 10 }}
          placeholder="password"
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <Button
          theme="success"
          style={{ marginTop: 20, marginBottom: 20, width: '100%', fontWeight: 'bold', fontSize: 16 }}
          onClick={() => signup()}
        >Create Account</Button>
        { validationError && <Alert theme="danger">{validationError}</Alert> }
        <a
          style={{ marginTop: 20, marginBottom: 20, width: '100%', fontSize: 13 }}
          onClick={(e) => {
            e.preventDefault()
            setPage('Login')
          }}
        >Already have an account?</a>
      </Form>
    </div>
    <div style={{ margin: 41 }}>
    <GoogleSignInButton
      onClick={() => firebase.auth().signInWithPopup(provider)}
    />
    </div>
    </div>
  )
}

export default Signup