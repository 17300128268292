import React, { useState } from 'react'
import firebase from './firebase'
import { FormInput, Form, Button, Alert } from 'shards-react'
import "./Login.css"

const Forget = ({ setPage }) => {

  const [ username, setUsername ] = useState('')
  const [ validationError, setValidationError ] = useState(null)
  const [ success, setSuccess ] = useState(false)

  const forget = () => {
    firebase.auth().sendPasswordResetEmail(username)
      .then(() => {
        setSuccess(true)
      })
      .catch((e) => {
        if (e && e.message) {
          setValidationError(e.message)
        } else {
          setValidationError("Something went wrong :s")
        }
      });
  };

  return (
    <div className="form-middle">
    <div className="form-middle-inner">
      <Form style={{ textAlign: 'center' }}>
        <h1>GreatList</h1>
        { !success ? <div><FormInput
          placeholder="email"
          value={username}
          type="email"
          onChange={e => setUsername(e.target.value)}
        />
        <Button
          theme="success"
          style={{ marginTop: 20, marginBottom: 20, width: '100%', fontWeight: 'bold', fontSize: 16 }}
          onClick={() => {
            if (username) {
              forget()
            }
          }}
        >Send password reset email</Button>
        </div>
        : <Alert theme="success" >Success! Please check your email account</Alert>}
        { validationError && <Alert theme="danger">{validationError}</Alert> }
        <a
          style={{ marginTop: 20, marginBottom: 20, width: '100%', fontSize: 13 }}
          onClick={(e) => {
            e.preventDefault()
            setPage('Login')
          }}
        >Already have an account?</a>
      </Form>
      <div style={{ margin: 10, textAlign: 'center', fontSize: 14 }}>
    Dont have an account? &nbsp;
      <a
        onClick={(e) => {
          e.preventDefault()
          setPage('Signup')
        }}
      >Create Account</a>
      </div>
    </div>
    </div>
  )
}

export default Forget