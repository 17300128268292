import firebaseSDK from 'firebase'
import moment from 'moment'
import firebase, { db } from '../firebase'

export const toFsType = todo => {
  let fsTodo = todo
  if (typeof todo.date === 'string') {
    fsTodo.date = moment(fsTodo.date, 'DD.MM.YYYY HH:mm').toDate()
  }
  if (typeof todo.remindDate === 'string') {
    fsTodo.remindDate = moment(fsTodo.remindDate, 'DD.MM.YYYY HH:mm').toDate()
  }
  if (typeof todo.createdAt === 'string') {
    fsTodo.createdAt = moment(fsTodo.createdAt).toDate()
  }
  return fsTodo
}

export const fromFsType = todo => {
  let glTodo = todo
  if (todo.date && typeof todo.date === 'object') {
    glTodo.date = moment(glTodo.date.toDate()).format('DD.MM.YYYY HH:mm')
  }
  if (todo.remindDate && typeof todo.remindDate === 'object') {
    glTodo.remindDate = moment(glTodo.remindDate.toDate()).format('DD.MM.YYYY HH:mm')
  }
  if (todo.createdAt && typeof todo.createdAt === 'object') {
    glTodo.createdAt = moment(glTodo.createdAt.toDate()).toISOString()
  }
  return glTodo
}

export const getTodosCreatedByUser = uid => {
  return db.collection('tasks').where("createdBy", "==", uid).get()
}

export const getTodosByList = listId =>  {
  return db.collection('tasks').where("list", "==", listId).get()
}

export const updateTodo = todo => {
  return db.collection('tasks').doc(todo.id).set(toFsType(todo))
}

export const deleteTodo = todoId => db.collection('tasks').doc(todoId).delete()

export const backlogId = 'rsPyFjsEsJw896fGoey8'
export const getBacklogItems = () => getTodosByList(backlogId)

export const likeTodo = (uid, todoId) => db.collection('tasks').doc(todoId).set({ likes: firebaseSDK.firestore.FieldValue.arrayUnion(uid)  }, { merge: true })
export const unlikeTodo = (uid, todoId) => db.collection('tasks').doc(todoId).set({ likes: firebaseSDK.firestore.FieldValue.arrayRemove(uid)  }, { merge: true })

export const addBacklogItem = (uid, email, text) => {
  const a = firebase.functions('europe-west2').httpsCallable('useCoin')
  return a({
    uid,
    email,
    text
  })
  .then(() => console.log('success'))
  .catch((e) => console.log('err', e));
} 
